import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { resoSearchRequestDto } from "../interface/resoSearchRequestDto"
import axios from "axios"
import { STATUS_FULLFILLED, STATUS_PENDING, STATUS_REJECTED } from "./store-constants"

interface resoSlice {
    resoSearchResult: any | null,
    resoSearchStatus: string
}

const initialState: resoSlice = {
    resoSearchResult: null,
    resoSearchStatus: ''
}

export const searchResi = createAsyncThunk(
    'reso/search',
    async (args:resoSearchRequestDto) => {
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_URL}/api/v1/reso/search?pageNum=${args.pageNum - 1}&stato=${args.stato}&dataResoInizio=${args.dataResoInizio}&dataResoFine=${args.dataResoFine}`)

        return response.data
    }
)

export const resoSlice = createSlice({
    name: 'reso',
    initialState,
    reducers: {

    },

    extraReducers:(builder) => {
        
        //search
        builder.addCase(searchResi.pending, (state: resoSlice) => {
            state.resoSearchStatus = STATUS_PENDING;
        });
        builder.addCase(searchResi.fulfilled, (state: resoSlice, action: any) => {
            state.resoSearchStatus = STATUS_FULLFILLED;
            state.resoSearchResult = action.payload;
        });
        builder.addCase(searchResi.rejected, (state: resoSlice) => {
            state.resoSearchStatus = STATUS_REJECTED;
        });
    },
})

export default resoSlice.reducer