import React, { useEffect, useState } from "react";
import PageHeader from "../../components/page-header";
import { HREF_PRODOTTI } from "../../constants/constants";
import { Card } from "react-bootstrap";
import { z } from "zod";
import { ProdottoInsertRequestDto } from "../../interface/prodottoInsertRequestDto";
import { useFormik, FormikProps } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { useNavigate } from "react-router";
import { insertProdotto } from "../../store/prodottoSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import { getAllCatalogo } from "../../store/catalogoSlice";
import { CustomSelectItem } from "../../interface/customSelectItem";

const validationSchema = z.object({
    name: z.string({ required_error: 'Campo obbligatorio' }),
    description: z.string().optional(),
    codice: z.string({ required_error: 'Campo obbligatorio' }),
    tipo: z.enum(['FRESCO', 'SECCO'], { required_error: 'Tipo deve essere FRESCO o SECCO' }),
    idCatalogo: z.string({ required_error: 'Campo obbligatorio' }),
    grammatura: z.number().optional(),
    pezziPerCartone: z.number().optional(),
    unitaDiMisura: z.string({ required_error: 'Campo obbligatorio' })
}).refine(data => {
    if (data.tipo === 'SECCO' && data.pezziPerCartone === undefined) {
        return false;
    }
    return true;
}, {
    message: 'Campo obbligatorio',
    path: [ 'pezziPerCartone']
});

const initialValues: ProdottoInsertRequestDto = {
    name: '',
    description: '',
    codice: '',
    tipo: '',
    idCatalogo: '',
    grammatura: '',
    pezziPerCartone: '',
    unitaDiMisura: ''
};

const NuovoProdotto = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { prodottoInsertStatus } = useAppSelector((state) => state.prodotto);
    const { catalogoGetAllResult } = useAppSelector((state) => state.catalogo);


    const form = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(validationSchema),
        onSubmit: (values: ProdottoInsertRequestDto) => {
            handleSubmit(values);
        },
    });

    const handleSubmit = (values: ProdottoInsertRequestDto) => {
        console.log(values);
        dispatch(insertProdotto(values));
    };

    const getFieldError = (
        form: FormikProps<ProdottoInsertRequestDto>,
        fieldName: keyof ProdottoInsertRequestDto
    ): string => {
        if (form.touched[fieldName]) {
            return form.errors[fieldName] || "";
        } else {
            return "";
        }
    };

    const goToBack = () => {
        navigate(HREF_PRODOTTI);
    };

    useEffect(() => {
        if (prodottoInsertStatus === STATUS_FULLFILLED) {
            form.resetForm();
        }
    }, [prodottoInsertStatus]);

    useEffect(() => {
        dispatch(getAllCatalogo())
    }, [])

    return (
        <>
            <PageHeader title="Nuovo Prodotto" urlBack={HREF_PRODOTTI} showIcon={true} />
            <Card border="light" className="table-wrapper table-responsive shadow-sm">
                <Card.Body className="pt-0">
                  
                        <div className="form-row mt-4">
                            <div className="form-group col-md-12">
                                <label htmlFor="name">Nome <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${getFieldError(form, "name") ? 'is-invalid' : ''}`}
                                    id="name"
                                    name="name"
                                    value={form.values.name}
                                    onChange={form.handleChange}
                                    placeholder="Inserire il nome" />
                                {getFieldError(form, "name") && (
                                    <div className="invalid-feedback">
                                        {getFieldError(form, "name")}
                                    </div>
                                )}
                            </div>

                            <div className="form-group col-md-12 mt-3">
                                <label htmlFor="description">Descrizione </label>
                                <input
                                    type="text"
                                    className={`form-control ${getFieldError(form, "description") ? 'is-invalid' : ''}`}
                                    id="description"
                                    name="description"
                                    value={form.values.description}
                                    onChange={form.handleChange}
                                    placeholder="Inserire la descrizione" />
                                {getFieldError(form, "description") && (
                                    <div className="invalid-feedback">
                                        {getFieldError(form, "description")}
                                    </div>
                                )}
                            </div>

                            <div className="form-group col-md-12 mt-3">
                                <label htmlFor="codice">Codice <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${getFieldError(form, "codice") ? 'is-invalid' : ''}`}
                                    id="codice"
                                    name="codice"
                                    value={form.values.codice}
                                    onChange={form.handleChange}
                                    placeholder="Inserire il codice" />
                                {getFieldError(form, "codice") && (
                                    <div className="invalid-feedback">
                                        {getFieldError(form, "codice")}
                                    </div>
                                )}
                            </div>

                            <div className="form-group col-md-12 mt-3">
                                <label htmlFor="tipo">Tipo <span className="text-danger">*</span></label>
                                <select
                                    className={`form-control ${getFieldError(form, "tipo") ? 'is-invalid' : ''}`}
                                    id="tipo"
                                    name="tipo"
                                    value={form.values.tipo}
                                    onChange={form.handleChange}
                                >
                                    <option value="">Seleziona</option>
                                    <option value="FRESCO">FRESCO</option>
                                    <option value="SECCO">SECCO</option>
                                </select>
                                {getFieldError(form, "tipo") && (
                                    <div className="invalid-feedback">
                                        {getFieldError(form, "tipo")}
                                    </div>
                                )}
                            </div>

                            <div className="form-group col-md-12 mt-3">
                                <label htmlFor="idCatalogo">Catalogo <span className="text-danger">*</span></label>
                                <select
                                    className={`form-control ${getFieldError(form, "idCatalogo") ? 'is-invalid' : ''}`}
                                    id="idCatalogo"
                                    name="idCatalogo"
                                    value={form.values.idCatalogo}
                                    onChange={form.handleChange}
                                >
                                    <option value="">Seleziona</option>

                                    {catalogoGetAllResult != null && catalogoGetAllResult?.data.map((value: any) => (
                                        <option key={value.id} value={value.id}>{value.nome}</option>
                                    ))}

                                </select>
                                {getFieldError(form, "idCatalogo") && (
                                    <div className="invalid-feedback">
                                        {getFieldError(form, "idCatalogo")}
                                    </div>
                                )}
                            </div>

                            {form.values.tipo === 'FRESCO' && (
                                <div className="form-group col-md-12 mt-3">
                                    <label htmlFor="grammatura">Grammatura </label>
                                    <input
                                        type="number"
                                        className={`form-control ${getFieldError(form, "grammatura") ? 'is-invalid' : ''}`}
                                        id="grammatura"
                                        name="grammatura"
                                        value={form.values.grammatura}
                                        onChange={form.handleChange}
                                        placeholder="Inserire la grammatura" />
                                    {getFieldError(form, "grammatura") && (
                                        <div className="invalid-feedback">
                                            {getFieldError(form, "grammatura")}
                                        </div>
                                    )}
                                </div>
                            )}

                            {form.values.tipo === 'SECCO' && (
                                <div className="form-group col-md-12 mt-3">
                                    <label htmlFor="pezziPerCartone">Pezzi per Cartone <span className="text-danger">*</span></label>
                                    <input
                                        type="number"
                                        className={`form-control ${getFieldError(form, "pezziPerCartone") ? 'is-invalid' : ''}`}
                                        id="pezziPerCartone"
                                        name="pezziPerCartone"
                                        value={form.values.pezziPerCartone}
                                        onChange={form.handleChange}
                                        placeholder="Inserire i pezzi per cartone" />
                                    {getFieldError(form, "pezziPerCartone") && (
                                        <div className="invalid-feedback">
                                            {getFieldError(form, "pezziPerCartone")}
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className="form-group col-md-12 mt-3">
                                <label htmlFor="unitaDiMisura">Unità di Misura <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${getFieldError(form, "unitaDiMisura") ? 'is-invalid' : ''}`}
                                    id="unitaDiMisura"
                                    name="unitaDiMisura"
                                    value={form.values.unitaDiMisura}
                                    onChange={form.handleChange}
                                    placeholder="Inserire l'unità di misura" />
                                {getFieldError(form, "unitaDiMisura") && (
                                    <div className="invalid-feedback">
                                        {getFieldError(form, "unitaDiMisura")}
                                    </div>
                                )}
                            </div>
                        </div>
                    
                    <div className="d-flex justify-content-end mb-4 mt-3">
                        <button className="btn btn-outline-primary mt-2 me-2" type="button" id="button-cln" onClick={() => goToBack()}>
                            Annulla
                        </button>
                        <button className="btn btn-primary mt-2" type="button" id="button-addon2" onClick={() => form.handleSubmit()}>
                            Salva
                        </button>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default NuovoProdotto;
