import React, { useEffect, useState } from "react";
import PageHeader from "../../components/page-header";
import { HREF_PRODOTTI } from "../../constants/constants";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { dettaglioProdotto } from "../../store/prodottoSlice";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import { Card } from "react-bootstrap";

function DettaglioProdotto() {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const { prodottoDettaglioResult, prodottoDettaglioStatus } = useAppSelector((state) => state.prodotto);
    const [prodotto, setProdotto] = useState<any>(null);

    useEffect(() => {
        if (id != undefined) {
            dispatch(dettaglioProdotto(id));
        }
    }, [id]);

    useEffect(() => {
        if (prodottoDettaglioStatus === STATUS_FULLFILLED) {
            setProdotto(prodottoDettaglioResult);
        }
    }, [prodottoDettaglioStatus]);

    return (
        <>
            <PageHeader title="Dettaglio Prodotto" urlBack={HREF_PRODOTTI} showIcon={true} />
            <div className="container mt-4">
                <Card border="light" className="shadow-lg rounded">
                    <Card.Body>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <div>
                                {/* <Card.Text className="fs-6">
                                    <span className="fw-bold">Id: </span>
                                    <span className="text-muted"> {prodotto?.id}</span>
                                </Card.Text>*/}
                                <Card.Text className="fs-6">
                                    <span className="fw-bold">Nome: </span>
                                    <span className="text-muted">{prodotto?.name}</span>
                                </Card.Text>
                                {prodotto?.description.length > 0 &&
                                    <Card.Text className="fs-6">
                                        <span className="fw-bold"> Descrizione: </span>
                                        <span className="text-muted"> {prodotto?.description}</span>
                                    </Card.Text>}
                                <Card.Text className="fs-6">
                                    <span className="fw-bold">Catalogo: </span>
                                    <span className="text-muted"> {prodotto?.nomeCatalogo}</span>
                                </Card.Text>
                                <Card.Text className="fs-6">
                                    <span className="fw-bold">Codice: </span>
                                    <span className="text-muted"> {prodotto?.codice}</span>
                                </Card.Text>
                                <Card.Text className="fs-6">
                                    <span className="fw-bold">Tipo: </span>
                                    <span className="text-muted"> {prodotto?.tipo}</span>
                                </Card.Text>
                                <Card.Text className="fs-6">
                                    <span className="fw-bold">Grammatura: </span>
                                    <span className="text-muted"> {prodotto?.grammatura}</span>
                                </Card.Text>
                                <Card.Text className="fs-6">
                                    <span className="fw-bold">Unità di misura: </span>
                                    <span className="text-muted"> {prodotto?.unitaDiMisura}</span>
                                </Card.Text>
                                <Card.Text className="fs-6">
                                    <span className="fw-bold">Pezzi per cartone: </span>
                                    <span className="text-muted"> {prodotto?.pezziPerCartone}</span>
                                </Card.Text>
                            </div>

                        </div>

                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default DettaglioProdotto;