import React, { useEffect, useState } from "react"
import PageHeader from "../../components/page-header"
import { HREF_ORDINI } from "../../constants/constants"
import { useParams } from "react-router"
import { useAppDispatch, useAppSelector } from "../../hooks";
import { dettaglioOrdini } from "../../store/ordiniSlice";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import { Card, ListGroup } from "react-bootstrap";
import { format } from "date-fns";

function DettaglioOrdine() {

    const { id } = useParams();
    const dispatch = useAppDispatch();
    const { ordiniDettaglioResult, ordiniDettaglioStatus } = useAppSelector((state) => state.ordini);
    const [ordine, setOrdine] = useState<any>(null);

    useEffect(() => {
        if (id != undefined) {
            dispatch(dettaglioOrdini(id));
        }
    }, [id])

    useEffect(() => {
        if (ordiniDettaglioStatus === STATUS_FULLFILLED) {
            setOrdine(ordiniDettaglioResult);
        }
    }, [ordiniDettaglioStatus])


    return (
        <>
            <PageHeader title="Dettaglio Ordine" urlBack={HREF_ORDINI} showIcon={true} />
            <div className="container mt-4">
                <Card border="light" className="shadow-lg rounded">
                    <Card.Body>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <div>
                                <Card.Text className="fs-6">
                                    <span className="fw-bold">Id: </span>
                                    <span className="text-muted"> {ordine?.id}</span>
                                </Card.Text>
                                <Card.Text className="fs-6">
                                    <span className="fw-bold">Effettuato da : </span>
                                    <span className="text-muted">{ordine?.nomeUtente} {ordine?.cognomeUtente}</span>
                                </Card.Text>

                                <Card.Text className="fs-6">
                                    <span className="fw-bold"> Data creazione: </span>
                                    <span className="text-muted">{ordine?.dataCreazione!+ null ? format(new Date(ordine?.dataCreazione), 'dd/MM/yyyy') : ''}</span>
                                </Card.Text>
                                <Card.Text className="fs-6">
                                    <span className="fw-bold"> Stato: </span>
                                    <span className="text-muted">{ordine?.stato}</span>
                                </Card.Text>

                            </div>

                        </div>
                        <Card.Header className="fs-4 fw-bold mb-3">Prodotti</Card.Header>
                        <ListGroup variant="flush">
                            {ordine?.ordine.length ? (
                                ordine.ordine.map((details: any) => (
                                    <ListGroup.Item key={details.id} className="d-grid gap-2">
                                        <div className="row">
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Codice:</strong> <br />
                                                <span className="text-muted">{details.prodotto.codice}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Nome:</strong> <br />
                                                <span className="text-muted">{details.prodotto.name}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Descrizione:</strong> <br />
                                                <span className="text-muted">{details.prodotto.description}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Pezzi per cartone:</strong> <br />
                                                <span className="text-muted">{details.prodotto.pezziPerCartone}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Tipo:</strong> <br />
                                                <span className="text-muted">{details.prodotto.tipo}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Quantità:</strong> <br />
                                                <span className="text-muted">{details.quantita}</span>
                                            </div>
                                            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <strong>Unità di misura:</strong> <br />
                                                <span className="text-muted">{details.unitaDiMisura}</span>
                                            </div>
                                        </div>
                                        <hr />
                                    </ListGroup.Item>
                                ))
                            ) : (
                                <ListGroup.Item className="text-center">Nessun prodotto disponibile</ListGroup.Item>
                            )}
                        </ListGroup>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default DettaglioOrdine