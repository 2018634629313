import React, { useEffect, useState } from "react";
import { z } from "zod";
import { resoSearchRequestDto } from "../../interface/resoSearchRequestDto";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import { format, startOfDay, endOfDay } from 'date-fns';
import { toFormikValidationSchema } from "zod-formik-adapter";
import { searchResi } from "../../store/resoSlice";
import PageHeader from "../../components/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEllipsisH, faEye, faSearch } from "@fortawesome/free-solid-svg-icons";
import { STATUS_FULLFILLED } from "../../store/store-constants";
import { Alert, Button, ButtonGroup, Card, Dropdown, Table } from "react-bootstrap";
import PaginationComponent from "../../components/Pagination/Pagination";

const schema = z.object({
    stato: z.string().optional(),
    dataResoInizio: z.string().optional(),
    dataResoFine: z.string().optional()
});

const initialValues: resoSearchRequestDto = {
    stato: '',
    dataResoFine: '',
    dataResoInizio: '',
    pageNum: 1,
};

function Resi() {

    const [resi, setResi] = useState<any>({
        data: [],
        totalElements: 0,
        pageNum: 1,
        pageSize: 10,
    })

    const { resoSearchResult, resoSearchStatus } = useAppSelector((store) => store.reso)

    const [currentPage, setCurrentPage] = useState(1);

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const form = useFormik({
        initialValues: initialValues,
        validationSchema: toFormikValidationSchema(schema),
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    let hasResults =
        resoSearchResult && resoSearchResult.totalElements
            ? resoSearchResult.totalElements > 0
            : false;

    const handleSubmit = (values: resoSearchRequestDto) => {

        const { dataResoInizio, dataResoFine } = values;

        const formattedDataResoInizio = dataResoInizio
            ? format(startOfDay(new Date(dataResoInizio)), "yyyy-MM-dd'T'HH:mm:ss")
            : '';

        const formattedDataResoFine = dataResoFine
            ? format(endOfDay(new Date(dataResoFine)), "yyyy-MM-dd'T'HH:mm:ss")
            : '';

        dispatch(searchResi({
            ...values,
            dataResoInizio: formattedDataResoInizio,
            dataResoFine: formattedDataResoFine,
        }))
    }

    useEffect(() => {
        dispatch(searchResi(initialValues))
    }, [])

    useEffect(() => {
        setResi(resoSearchResult)
    }, [resoSearchResult])

    const reset = () => {
        form.resetForm();
        setCurrentPage(1);
        form.handleSubmit();
    }

    const handlePageChange = (pageNum: number) => {
        form.setFieldValue('pageNum', pageNum)
        setCurrentPage(pageNum)
        form.handleSubmit()
    };

    const avviaRicerca = () => {
        form.setFieldValue('pageNum', 1);
        setCurrentPage(1);
        form.handleSubmit()
    }

    return (
        <>
            <PageHeader title="Resi" />
            <div className="mt-4">
                <div className="row mb-4">
                    <div className="col-md-4">
                        <label htmlFor="stato">Stato</label>
                        <select
                            id="stato"
                            className="form-control"
                            name="stato"
                            value={form.values.stato}
                            onChange={form.handleChange}
                        >
                            <option value=''>Seleziona...</option>
                            <option value='INVIATO'>INVIATO</option>
                            <option value='ACCETTATO'>ACCETTATO</option>
                            <option value='ANNULLATO'>ANNULLATO</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="data-reso-da">Data reso da</label>
                        <input
                            type="date"
                            id="data-reso-da"
                            className="form-control"
                            name="dataResoInizio"
                            value={form.values.dataResoInizio}
                            onChange={form.handleChange}
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="data-reso-a">Data reso a</label>
                        <input
                            type="date"
                            id="data-reso-a"
                            className="form-control"
                            name="dataResoFine"
                            value={form.values.dataResoFine}
                            onChange={form.handleChange}
                        />
                    </div>

                </div>
                <div className="d-flex justify-content-end mb-4">
                    <button
                        className="btn btn-outline-primary mt-2 me-2"
                        type="button"
                        id="button-cln"
                        onClick={() => { reset() }}
                    >
                        Reset
                    </button>
                    <button className="btn btn-primary mt-2" type="button" id="button-addon2" onClick={() => avviaRicerca()}>
                        <FontAwesomeIcon icon={faSearch} className="me-1" />
                        Cerca
                    </button>
                </div>
            </div>
            {resi != null && resoSearchStatus === STATUS_FULLFILLED && resi.totalElements === 0 && <Alert color="info">Nessun risultato disponibile</Alert>}
            {resoSearchStatus === STATUS_FULLFILLED && hasResults && (
                <Card border="light" className="table-wrapper table-responsive shadow-sm">
                    <Card.Body className="pt-0">
                        <Table hover className="user-table align-items-center">
                            <thead>
                                <tr>
                                    <th className="border-bottom">#</th>
                                    <th className="border-bottom">Motivo</th>
                                    <th className="border-bottom">Data Reso</th>
                                    <th className="border-bottom">Stato</th>
                                    <th className="border-bottom">Reso Totale</th>
                                    <th className="border-bottom"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {resi != null && resi.data.map((t: any) => (
                                    <tr key={t.id}>
                                        <td>{t.id}</td>
                                        <td>
                                            <span className="fw-normal">{t.motivoReso}</span>
                                        </td>
                                        <td>
                                            <span className="fw-normal">
                                                {format(new Date(t.dataReso), 'dd/MM/yyyy')}
                                            </span>
                                        </td>
                                        <td>
                                            <span className={`fw-normal`}>
                                                {t.stato}
                                            </span>
                                        </td>
                                        <td>
                                            <span className={`fw-normal`}>
                                                {t.isTotal}
                                            </span>
                                        </td>
                                        <td>
                                            <Dropdown as={ButtonGroup}>
                                                <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                                                    <span className="icon icon-sm">
                                                        <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => { navigate('') }}>
                                                        <FontAwesomeIcon icon={faEye} className="me-2" /> Dettaglio
                                                    </Dropdown.Item>
                                                    {t.stato != "COMPLETATO" && <Dropdown.Item onClick={() => { }}>
                                                        <FontAwesomeIcon icon={faEdit} className="me-2" /> Aggiorna stato
                                                    </Dropdown.Item>}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                            {resi != null ? (<PaginationComponent
                                totalCount={resi.totalElements}
                                siblingCount={1}
                                currentPage={currentPage}
                                pageSize={resi.pageSize}
                                onPageChange={handlePageChange}
                                className="justify-content-center"
                            />) : <></>}
                        </Card.Footer>
                    </Card.Body>
                </Card>
            )}
        </>
    )
}

export default Resi;